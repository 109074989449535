import React, { useState, useEffect } from 'react';
import { supabase } from './supabaseClient';
import LogoutButton from './LogoutButton';
import UserDisplay from './UserDisplay';
import { useSwipeable } from 'react-swipeable';

const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

// -----------------------
// Funciones de encriptación
// -----------------------
const getEncryptionKey = async (userId) => {
  const encoder = new TextEncoder();
  const keyMaterial = await crypto.subtle.importKey(
    'raw',
    encoder.encode(userId),
    { name: 'PBKDF2' },
    false,
    ['deriveBits', 'deriveKey']
  );
  return await crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt: encoder.encode('your-salt-here'),
      iterations: 100000,
      hash: 'SHA-256'
    },
    keyMaterial,
    { name: 'AES-GCM', length: 256 },
    false,
    ['encrypt', 'decrypt']
  );
};

const encryptNote = async (text, key) => {
  const encoder = new TextEncoder();
  const iv = crypto.getRandomValues(new Uint8Array(12));
  const encrypted = await crypto.subtle.encrypt(
    { name: 'AES-GCM', iv },
    key,
    encoder.encode(text)
  );
  return {
    encrypted: Array.from(new Uint8Array(encrypted)),
    iv: Array.from(iv)
  };
};

const decryptNote = async (encryptedData, key) => {
  try {
    const decoder = new TextDecoder();
    const decrypted = await crypto.subtle.decrypt(
      { name: 'AES-GCM', iv: new Uint8Array(encryptedData.iv) },
      key,
      new Uint8Array(encryptedData.encrypted)
    );
    return decoder.decode(decrypted);
  } catch (error) {
    // Si falla la desencriptación, se asume que la nota está sin encriptar
    return encryptedData;
  }
};

// -----------------------
// Componente EnhancedCalendar
// -----------------------
const EnhancedCalendar = ({ user, setShowLogin }) => {
  // Estados
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [notes, setNotes] = useState({});
  const [currentNote, setCurrentNote] = useState('');
  const [error, setError] = useState(null);
  const [holidays, setHolidays] = useState({});
  const [userLocation, setUserLocation] = useState(null);
  const [slideDirection, setSlideDirection] = useState(null);

  // -----------------------
  // useEffect para obtener ubicación y notas
  // -----------------------
  useEffect(() => {
    getUserLocation();
    if (user) {
      fetchNotes(user);
    } else {
      setNotes({});
    }
  }, [user]);

  useEffect(() => {
    if (userLocation) {
      fetchHolidays();
    }
  }, [userLocation, currentDate]);

  // -----------------------
  // Funciones auxiliares
  // -----------------------

  // Obtiene la ubicación del usuario
  const getUserLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        },
        (error) => {
          console.error('Error getting user location:', error);
          setUserLocation(null);
        }
      );
    } else {
      console.error('Geolocation is not available in this browser.');
      setUserLocation(null);
    }
  };

  // Obtiene los días festivos según la ubicación y el mes actual
  const fetchHolidays = async () => {
    if (!userLocation) {
      setHolidays({});
      return;
    }
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const lastDay = new Date(year, month, 0).getDate();
    try {
      const geoResponse = await fetch(
        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${userLocation.latitude}&longitude=${userLocation.longitude}`
      );
      const geoData = await geoResponse.json();
      const countryCode = geoData.countryCode;

      const response = await fetch(
        `https://openholidaysapi.org/PublicHolidays?countryIsoCode=${countryCode}&languageIsoCode=EN&validFrom=${year}-${month
          .toString()
          .padStart(2, '0')}-01&validTo=${year}-${month.toString().padStart(2, '0')}-${lastDay}&latitude=${userLocation.latitude}&longitude=${userLocation.longitude}`
      );
      const data = await response.json();

      const holidayMap = (data || [])
        .filter((holiday) => holiday.nationwide || holiday.regionalScope === 'Regional')
        .reduce((acc, holiday) => {
          const date = new Date(holiday.startDate);
          const dateString = formatDate(date);
          const holidayName = holiday.name.find((n) => n.language === 'EN')?.text || holiday.name[0].text;
          acc[dateString] = {
            name: holidayName,
            scope: holiday.nationwide ? 'National' : holiday.regionalScope,
            region: holiday.subdivisions?.[0]?.shortName || ''
          };
          return acc;
        }, {});

      setHolidays(holidayMap);
      console.log('Fetched holidays:', holidayMap);
    } catch (error) {
      console.error('Error fetching holidays:', error);
      setError('Failed to fetch holidays. Please try again.');
    }
  };

  // Obtiene las notas del usuario y trata de desencriptarlas
  const fetchNotes = async (user) => {
    try {
      const { data, error } = await supabase
        .from('notes')
        .select('*')
        .eq('user_id', user.id);
      if (error) throw error;

      const key = await getEncryptionKey(user.id);
      const notesObject = {};

      for (const note of data) {
        let content;
        try {
          const parsedContent = JSON.parse(note.content);
          content = await decryptNote(parsedContent, key);
        } catch (e) {
          content = note.content;
        }

        if (!notesObject[note.date]) {
          notesObject[note.date] = [];
        }
        notesObject[note.date].push({
          id: note.id,
          content,
          completed: note.completed
        });
      }

      setNotes(notesObject);
    } catch (error) {
      console.error('Error fetching notes:', error);
      setError('Failed to fetch notes. Please try again.');
    }
  };

  // Formatea una fecha al formato "yyyy-mm-dd"
  const formatDate = (date) => {
    const d = new Date(date);
    d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
    return d.toISOString().split('T')[0];
  };

  // Maneja el click sobre una fecha del calendario
  const handleDateClick = (date) => {
    setSelectedDate(date);
    setCurrentNote('');
    setError(null);
  };

  // Agrega una nota (inicialmente sin completar)
  const handleAddNote = async () => {
    if (selectedDate && currentNote.trim() !== '' && user) {
      const dateKey = formatDate(selectedDate);
      try {
        const key = await getEncryptionKey(user.id);
        const encryptedData = await encryptNote(currentNote, key);

        const { data, error } = await supabase
          .from('notes')
          .insert({
            user_id: user.id,
            date: dateKey,
            content: JSON.stringify(encryptedData),
            completed: false
          })
          .select();

        if (error) throw error;

        setNotes((prevNotes) => ({
          ...prevNotes,
          [dateKey]: [
            ...(prevNotes[dateKey] || []),
            { id: data[0].id, content: currentNote, completed: false }
          ]
        }));
        setCurrentNote('');
        setError(null);
      } catch (error) {
        console.error('Error adding note:', error);
        setError('Failed to add note. Please try again.');
      }
    }
  };

  // Permite agregar la nota al presionar Enter
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddNote();
    }
  };

  // Elimina una nota
  const handleDeleteNote = async (dateKey, noteId) => {
    if (user) {
      try {
        const { error } = await supabase
          .from('notes')
          .delete()
          .eq('id', noteId)
          .eq('user_id', user.id);

        if (error) throw error;

        setNotes((prevNotes) => {
          const updatedNotes = { ...prevNotes };
          updatedNotes[dateKey] = updatedNotes[dateKey].filter((note) => note.id !== noteId);
          if (updatedNotes[dateKey].length === 0) {
            delete updatedNotes[dateKey];
          }
          return updatedNotes;
        });
        setError(null);
      } catch (error) {
        console.error('Error deleting note:', error);
        setError('Failed to delete note. Please try again.');
      }
    }
  };

  // Alterna el estado "completed" de una nota
  const handleToggleNoteCompleted = async (dateKey, noteId, currentCompleted) => {
    if (user) {
      try {
        const { error } = await supabase
          .from('notes')
          .update({ completed: !currentCompleted })
          .eq('id', noteId)
          .eq('user_id', user.id);

        if (error) throw error;

        setNotes((prevNotes) => {
          const updatedNotes = { ...prevNotes };
          updatedNotes[dateKey] = updatedNotes[dateKey].map((note) =>
            note.id === noteId ? { ...note, completed: !currentCompleted } : note
          );
          return updatedNotes;
        });
      } catch (error) {
        console.error('Error toggling note completion:', error);
        setError('Failed to update note completion. Please try again.');
      }
    }
  };

  // Maneja el logout del usuario
  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error signing out:', error);
      setError('Failed to sign out. Please try again.');
    }
  };

  // Cambia de mes con animación hacia la derecha
  const handlePrevMonth = () => {
    setSlideDirection('right');
    setTimeout(() => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
      setSlideDirection(null);
    }, 300);
  };

  // Cambia de mes con animación hacia la izquierda
  const handleNextMonth = () => {
    setSlideDirection('left');
    setTimeout(() => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
      setSlideDirection(null);
    }, 300);
  };

  // Configuración del swipe para que se comporte igual que las flechas
  const handlers = useSwipeable({
    onSwipedLeft: handleNextMonth,
    onSwipedRight: handlePrevMonth,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  // Calcula los días a mostrar en el calendario (incluye días de meses adyacentes)
  const getDaysArray = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    let firstDay = new Date(year, month, 1).getDay();
    firstDay = firstDay === 0 ? 6 : firstDay - 1; // Ajusta para que la semana inicie en lunes
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const daysInPrevMonth = new Date(year, month, 0).getDate();
    const days = [];

    // Días del mes anterior
    for (let i = firstDay - 1; i >= 0; i--) {
      days.push({
        date: new Date(year, month - 1, daysInPrevMonth - i),
        isCurrentMonth: false
      });
    }
    // Días del mes actual
    for (let i = 1; i <= daysInMonth; i++) {
      days.push({
        date: new Date(year, month, i),
        isCurrentMonth: true
      });
    }
    // Días del mes siguiente
    const remainingDays = 42 - days.length;
    for (let i = 1; i <= remainingDays; i++) {
      days.push({
        date: new Date(year, month + 1, i),
        isCurrentMonth: false
      });
    }
    return days;
  };

  const today = new Date();
  const daysArray = getDaysArray();

  return (
    <div className="w-full max-w-md mx-auto">
      {/* Cabecera con usuario y botón de logout */}
      <div className="flex justify-between items-center mb-4">
        {user && <UserDisplay user={user} />}
        {user && <LogoutButton onLogout={handleLogout} />}
      </div>

      {/* Navegación entre meses */}
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={handlePrevMonth}
          className="text-gray-600 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-300 rounded-full p-1"
        >
          ←
        </button>
        <h2 className="text-xl font-light">
          {currentDate.toLocaleString('en-US', { month: 'long', year: 'numeric' })}
        </h2>
        <button
          onClick={handleNextMonth}
          className="text-gray-600 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-300 rounded-full p-1"
        >
          →
        </button>
      </div>

      {/* Calendario: grid de días con swipe */}
      <div
        {...handlers}
        className={`grid grid-cols-7 gap-1 mb-4 transition-all duration-300 ${
          slideDirection === 'left'
            ? 'translate-x-full opacity-0'
            : slideDirection === 'right'
            ? '-translate-x-full opacity-0'
            : 'translate-x-0 opacity-100'
        }`}
      >
        {daysOfWeek.map((day) => (
          <div key={day} className="text-center font-light text-gray-500">
            {day}
          </div>
        ))}
        {daysArray.map(({ date, isCurrentMonth }) => {
          const dateKey = formatDate(date);
          const isSelected = selectedDate && formatDate(selectedDate) === dateKey;
          const hasNotes = notes[dateKey] && notes[dateKey].length > 0;
          const isToday =
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
          const holiday = holidays[dateKey];
          return (
            <button
              key={dateKey}
              onClick={() => handleDateClick(date)}
              className={`text-center p-2 rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300
                ${isSelected ? 'bg-gray-200' : ''}
                ${hasNotes ? 'font-bold' : ''}
                ${isToday ? 'bg-gray-100 ring-2 ring-gray-300' : ''}
                ${!isCurrentMonth ? 'text-gray-300' : ''}
                ${holiday ? 'text-red-500 font-bold' : ''}
              `}
              title={
                holiday
                  ? `${holiday.name} (${holiday.scope}${
                      holiday.region ? ` - ${holiday.region}` : ''
                    })`
                  : ''
              }
            >
              {date.getDate()}
            </button>
          );
        })}
      </div>

      {/* Sección para agregar y listar notas del día seleccionado */}
      {selectedDate && user && (
        <div className="mt-4">
          <h3 className="text-lg font-light mb-2">
            {selectedDate.toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            })}
            {holidays[formatDate(selectedDate)] && (
              <span className="ml-2 text-red-500 font-normal">
                {holidays[formatDate(selectedDate)].name}
                <span className="text-sm ml-2 text-gray-500">
                  (
                  {holidays[formatDate(selectedDate)].scope}
                  {holidays[formatDate(selectedDate)].region &&
                    ` - ${holidays[formatDate(selectedDate)].region}`}
                  )
                </span>
              </span>
            )}
          </h3>
          {/* Formulario para agregar nota */}
          <div className="flex mb-2">
            <input
              type="text"
              value={currentNote}
              onChange={(e) => setCurrentNote(e.target.value)}
              onKeyPress={handleKeyPress}
              className="flex-grow p-2 border rounded-l-md font-light focus:outline-none focus:ring-2 focus:ring-gray-300"
              placeholder="Enter a note..."
            />
            <button
              onClick={handleAddNote}
              className="bg-gray-200 text-gray-800 px-4 rounded-r-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300"
            >
              Add
            </button>
          </div>
          {error && <p className="text-red-500 text-sm mb-2">{error}</p>}
          {/* Listado de notas con checkbox para marcar como completadas */}
          <ul className="space-y-2">
            {notes[formatDate(selectedDate)]?.map((note) => (
              <li key={note.id} className="flex items-center bg-gray-100 p-2 rounded">
                <div className="custom-checkbox">
                  <input
                    type="checkbox"
                    id={`note-${note.id}`}
                    checked={note.completed}
                    onChange={() =>
                      handleToggleNoteCompleted(
                        formatDate(selectedDate),
                        note.id,
                        note.completed
                      )
                    }
                  />
                  <label htmlFor={`note-${note.id}`}>{note.content}</label>
                </div>
                <button
                  onClick={() => handleDeleteNote(formatDate(selectedDate), note.id)}
                  className="text-red-500 hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-300 rounded-full p-1 ml-auto"
                >
                  ×
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Botón de login si no hay usuario */}
      {!user && (
        <div className="mt-4 text-center">
          <button
            onClick={() => setShowLogin(true)}
            className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-light py-2 px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50"
          >
            Wanna take notes? Log in
          </button>
        </div>
      )}
    </div>
  );
};

export default EnhancedCalendar;
